import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
    display: block;
    position: relative;
    width: 100%;
    background: #0e1828;
    color: #fff;
`;
const OuterWrapper = styled.div`
    display: block;
    width: 100%;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
`;
const InnerWrapper = styled.div`
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    width: 100%;
`;
const RowWrapper = styled.div`
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    width: 100%;
    padding: 7rem 4rem;
    @media only screen and (min-width: 768px) {
        flex-flow: row wrap;
        padding: 10rem 12rem;
    }
`;
const Column = styled.div`
    display: flex;
    flex-flow: row nowrap;
    flex: 0 1 auto;
    width: 100%;
    margin-bottom: 4rem;
    @media only screen and (min-width: 768px) {
        width: calc(50% - 1.5rem);
        margin-right: 3rem;
        &:nth-child(2n) {
            margin-right: 0;
        }
    }
    @media only screen and (min-width: 1024px) {
        width: calc(33.33333% - 5rem);
        margin-right: 7.5rem;
        &:nth-child(2n) {
            margin-right: 7.5rem;
        }
        &:nth-child(3n) {
            margin-right: 0;
        }
    }
`;
const ContentContainer = styled.div`
    display: block;
    flex: 0 1 auto;
`;
const SubtitleContainer = styled.div`
    display: block;
    flex: 0 0 auto;
    width: 3rem;
`;
const Subtitle = styled.span`
    display: block;
    font-size: 1rem;
    line-height: 3.25rem;
    font-weight: 600;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    color: #5fddeb;
`;
const Heading3 = styled.h3`
    display: block;
    width: 100%;
    font-family: 'Poppins';
    font-size: 2.6rem;
    line-height: 1.25;
    font-weight: 400;
    margin: 0 0 1.5rem 0;
`;
const Content = styled.div`
    display: block;
    > p {
        font-family: 'Poppins';
        font-size: 1.4rem;
        line-height: 1.5;
        font-weight: 300;
        margin-bottom: 0;
    }
`;
const BigContent4 = ({ ...props }) => {
    return (
        <Wrapper>
            <OuterWrapper>
                <InnerWrapper>
                    <RowWrapper>
                        {props.column1Heading ? (
                            <Column>
                                <SubtitleContainer>
                                    <Subtitle
                                        dangerouslySetInnerHTML={{ __html: props.column1Subtitle }}
                                    />
                                </SubtitleContainer>
                                <ContentContainer>
                                    <Heading3
                                        dangerouslySetInnerHTML={{ __html: props.column1Heading }}
                                    ></Heading3>
                                    <Content>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: props.column1Content,
                                            }}
                                        ></p>
                                    </Content>
                                </ContentContainer>
                            </Column>
                        ) : (
                            ''
                        )}
                        {props.column2Heading ? (
                            <Column>
                                <SubtitleContainer>
                                    <Subtitle
                                        dangerouslySetInnerHTML={{ __html: props.column2Subtitle }}
                                    />
                                </SubtitleContainer>
                                <ContentContainer>
                                    <Heading3
                                        dangerouslySetInnerHTML={{ __html: props.column2Heading }}
                                    ></Heading3>
                                    <Content>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: props.column2Content,
                                            }}
                                        ></p>
                                    </Content>
                                </ContentContainer>
                            </Column>
                        ) : (
                            ''
                        )}
                        {props.column3Heading ? (
                            <Column>
                                <SubtitleContainer>
                                    <Subtitle
                                        dangerouslySetInnerHTML={{ __html: props.column3Subtitle }}
                                    />
                                </SubtitleContainer>
                                <ContentContainer>
                                    <Heading3
                                        dangerouslySetInnerHTML={{ __html: props.column3Heading }}
                                    ></Heading3>
                                    <Content>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: props.column3Content,
                                            }}
                                        ></p>
                                    </Content>
                                </ContentContainer>
                            </Column>
                        ) : (
                            ''
                        )}
                        {props.column4Heading ? (
                            <Column>
                                <SubtitleContainer>
                                    <Subtitle
                                        dangerouslySetInnerHTML={{ __html: props.column4Subtitle }}
                                    />
                                </SubtitleContainer>
                                <ContentContainer>
                                    <Heading3
                                        dangerouslySetInnerHTML={{ __html: props.column4Heading }}
                                    ></Heading3>
                                    <Content>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: props.column4Content,
                                            }}
                                        ></p>
                                    </Content>
                                </ContentContainer>
                            </Column>
                        ) : (
                            ''
                        )}
                        {props.column5Heading ? (
                            <Column>
                                <SubtitleContainer>
                                    <Subtitle
                                        dangerouslySetInnerHTML={{ __html: props.column5Subtitle }}
                                    />
                                </SubtitleContainer>
                                <ContentContainer>
                                    <Heading3
                                        dangerouslySetInnerHTML={{ __html: props.column5Heading }}
                                    ></Heading3>
                                    <Content>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: props.column5Content,
                                            }}
                                        ></p>
                                    </Content>
                                </ContentContainer>
                            </Column>
                        ) : (
                            ''
                        )}
                        {props.column6Heading ? (
                            <Column>
                                <SubtitleContainer>
                                    <Subtitle
                                        dangerouslySetInnerHTML={{ __html: props.column6Subtitle }}
                                    />
                                </SubtitleContainer>
                                <ContentContainer>
                                    <Heading3
                                        dangerouslySetInnerHTML={{ __html: props.column6Heading }}
                                    ></Heading3>
                                    <Content>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: props.column6Content,
                                            }}
                                        ></p>
                                    </Content>
                                </ContentContainer>
                            </Column>
                        ) : (
                            ''
                        )}
                    </RowWrapper>
                </InnerWrapper>
            </OuterWrapper>
        </Wrapper>
    );
};

export default BigContent4;
