import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

const Wrapper = styled.section`
    display: block;
    position: relative;
    width: 100%;
    background-color: ${(props) => props.backgroundColor};
    color: #0e1828;
    overflow: hidden;
    .outer-wrapper {
        display: block;
        width: 100%;
        .inner-wrapper {
            display: flex;
            flex-flow: column nowrap;
            width: 100%;
            .row-wrapper {
                display: flex;
                flex-flow: column nowrap;
                width: 100%;
                .column {
                    display: block;
                    width: 100%;
                    &.content-container {
                        padding: 7rem 4rem;
                    }
                    .content {
                        display: block;
                        > p {
                            font-size: 1.4rem;
                            line-height: 1.5;
                            font-weight: 300;
                            margin-bottom: 0;
                            &.subtitle {
                                font-size: 1rem;
                                line-height: 1.5;
                                font-weight: 600;
                                letter-spacing: 0.3em;
                                text-transform: uppercase;
                                margin-top: 4rem;
                                margin-bottom: 1.6rem;
                            }
                        }
                        span.subheading {
                            display: block;
                            font-family: 'Poppins';
                            font-size: 1rem;
                            line-height: 1.5;
                            font-weight: 600;
                            text-transform: uppercase;
                            letter-spacing: 0.3em;
                            color: #0e1828;
                            margin-bottom: 1.5rem;
                        }
                        h3 {
                            display: block;
                            width: 100%;
                            font-family: 'Source Serif Pro';
                            font-size: 2rem;
                            line-height: 1.6;
                            font-weight: 400;
                            margin: 0 0 1.6rem 0;
                        }
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 768px) {
        .outer-wrapper {
            .inner-wrapper {
                .row-wrapper {
                    .column {
                        &.content-container {
                            padding: 4rem 12rem 10rem 12rem;
                        }
                        .content {
                            > p {
                                &.subtitle {
                                    margin-top: 6rem;
                                }
                            }
                            h3 {
                                font-size: 2.6rem;
                                line-height: 1.2;
                            }
                        }
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 1024px) {
        .outer-wrapper {
            .inner-wrapper {
                .row-wrapper {
                    flex-flow: row wrap;
                    align-items: center;
                    min-height: 600px;
                    .column {
                        width: 50%;
                        &.image-container {
                            order: 1;
                        }
                        &.content-container {
                            order: 2;
                            width: 50%;
                            max-width: 750px;
                            margin-right: auto;
                            padding: 10rem 12rem 10rem 4rem;
                        }
                    }
                }
            }
        }
    }
`;
const TextAndImage2 = ({ ...props }) => {
    return (
        <Wrapper backgroundImage={props.backgroundImage} backgroundColor={props.backgroundColor}>
            <div className="outer-wrapper">
                <div className="inner-wrapper">
                    <div className="row-wrapper row-one">
                        <div className="column content-container">
                            <div className="content">
                                <span
                                    className="subheading"
                                    dangerouslySetInnerHTML={{ __html: props.subheading }}
                                />
                                <h3 dangerouslySetInnerHTML={{ __html: props.heading }} />
                                <p dangerouslySetInnerHTML={{ __html: props.content }}></p>
                                <p
                                    className="subtitle"
                                    dangerouslySetInnerHTML={{ __html: props.content2 }}
                                ></p>
                                <GatsbyImage
                                    image={props.clientLogos.childImageSharp.gatsbyImageData}
                                    alt=""
                                    objectFit="contain"
                                />
                            </div>
                        </div>
                        <div className="column image-container">
                            <GatsbyImage
                                image={props.imageMobile.childImageSharp.gatsbyImageData}
                                alt=""
                                objectFit="contain"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default TextAndImage2;
