import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: block;
    width: 100%;
    height: 190px;
    background: url(${(props) =>
            props.imageMobile.childImageSharp.gatsbyImageData.images.fallback.src})
        top left/contain repeat ${(props) => props.backgroundColour};
    @media only screen and (min-width: 1024px) {
        height: 265px;
        background: url(${(props) =>
                props.imageMobile.childImageSharp.gatsbyImageData.images.fallback.src})
            top left/contain repeat ${(props) => props.backgroundColour};
    }
`;

const Pattern1 = ({ ...props }) => {
    return <Wrapper imageMobile={props.imageMobile} backgroundColour={props.backgroundColour} />;
};

export default Pattern1;
