import React from 'react';
import styled, { keyframes } from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

const float = keyframes`
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-30px);
    }
    100% {
        transform: translateY(0px);
    }
`;

const Wrapper = styled.section`
    display: block;
    position: relative;
    width: 100%;
    background: #fff;
    color: #0e1828;
    overflow: hidden;
    svg.floating-shape {
        display: block;
        position: absolute;
        bottom: -93px;
        right: -91px;
        content: '';
        width: 182px;
        height: auto;
        font-size: 0;
        pointer-events: none;
        animation: ${float} 12s both infinite ease-in-out;
        path {
            fill: ${(props) => props.shapeColour};
        }
    }
    .outer-wrapper {
        display: block;
        position: relative;
        width: 100%;
        z-index: 1;
        .inner-wrapper {
            display: flex;
            flex-flow: column nowrap;
            width: 100%;
            .row-wrapper {
                display: flex;
                flex-flow: column nowrap;
                width: 100%;
                .column {
                    display: block;
                    position: relative;
                    width: 100%;
                    overflow: hidden;
                    &.content-container {
                        padding: 4rem 4rem 7rem 4rem;
                        h2 {
                            display: block;
                            width: 100%;
                            font-family: 'Source Serif Pro';
                            font-style: italic;
                            font-size: 4rem;
                            line-height: 1.2;
                            font-weight: 400;
                            margin: 0 0 2.4rem 0;
                        }
                        .content {
                            display: block;
                            max-width: 420px;
                            margin: auto;
                            > p {
                                font-size: 1.4rem;
                                line-height: 1.5;
                                font-weight: 300;
                                margin-bottom: 0;
                                &.subtitle {
                                    margin-top: 4rem;
                                    margin-bottom: 1.6rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 1024px) {
        svg.floating-shape {
            bottom: -140px;
            right: -150px;
            width: 310px;
        }
        .outer-wrapper {
            .inner-wrapper {
                .row-wrapper {
                    flex-flow: row wrap;
                    align-items: center;
                    min-height: 600px;
                    .column {
                        &.content-container {
                            width: 50%;
                            max-width: 750px;
                            margin: 0 auto 0 0;
                            &:after {
                                display: none;
                            }
                            h2 {
                                font-size: 7rem;
                            }
                        }
                        &.image-container {
                            width: 50%;
                        }
                    }
                }
            }
        }
    }
`;
const TextAndImage7 = ({ ...props }) => {
    const { width } = useWindowDimensions();

    return (
        <Wrapper width={width} shapeColour={props.shapeColour}>
            <div className="outer-wrapper">
                <div className="inner-wrapper">
                    <div className="row-wrapper row-one">
                        <div className="column image-container">
                            <GatsbyImage
                                image={props.image.childImageSharp.gatsbyImageData}
                                alt=""
                                objectFit="contain"
                            />
                        </div>
                        <div className="column content-container">
                            <div className="content">
                                <h2 dangerouslySetInnerHTML={{ __html: props.heading }} />
                                <p dangerouslySetInnerHTML={{ __html: props.content }}></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <svg className="floating-shape" viewBox="0 0 311 313" fill="none">
                <path
                    d="M271.912 35.3781C274.862 39.0451 357.035 143.801 273.277 275.102C273.114 275.321 273.004 275.54 272.84 275.759C272.84 275.759 257.214 287.854 230.88 298.253C195.257 312.319 140.019 323.32 77.4607 297.049C64.5665 291.631 51.2898 284.625 37.8493 275.759C37.8493 275.704 37.7946 275.704 37.7946 275.649C-44.5424 151.299 32.5495 43.9162 37.6307 37.0748C37.7946 36.8559 37.9039 36.7464 37.9039 36.7464C37.9039 36.7464 53.4206 25.1433 79.3729 15.018C115.542 0.842552 172.036 -10.5416 235.36 16.0032C247.325 21.0385 259.509 27.3873 271.857 35.3234C271.857 35.2687 271.912 35.3234 271.912 35.3781Z"
                    fill="#E2246E"
                />
            </svg>
        </Wrapper>
    );
};

export default TextAndImage7;
