import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import MastheadLandingPage from '../components/content/mastheads/landingPage';
import TextAndImage2 from '../components/content/TextAndImage/2';
import BigContent4 from '../components/content/BigContent/4';
import Pattern1 from '../components/global/pattern/1';
import TextAndImage3 from '../components/content/TextAndImage/3';
import TextAndImage4 from '../components/content/TextAndImage/4';
import TextAndImage7 from '../components/content/TextAndImage/7';
import PageNavigationBlock from '../components/content/pageNavigationBlock';

import mastheadImageWebp from '../images/about/about-page-masthead.webp';
import mastheadImagePng from '../images/about/about-page-masthead.png';

import pageNavigationBlockImage from '../images/home/slides/home-slide-3.png';

const AboutPage = ({ transitionStatus, ...props }) => {
    // useEffect(() => {
    //   console.log('About Page', transitionStatus);
    // }, [transitionStatus]);

    const data = useStaticQuery(graphql`
        {
            textAndImage2: file(
                name: { eq: "about-page-text-and-image-2" }
                extension: { eq: "jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE, layout: CONSTRAINED)
                }
            }
            clientLogos: file(name: { eq: "about-page-client-logos" }, extension: { eq: "png" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE, layout: CONSTRAINED)
                }
            }
            pattern1ImageMobile: file(name: { eq: "pattern-1-mobile" }, extension: { eq: "png" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE, layout: CONSTRAINED)
                }
            }
            pattern1ImageDesktop: file(
                name: { eq: "pattern-1-desktop" }
                extension: { eq: "png" }
            ) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE, layout: CONSTRAINED)
                }
            }
            textAndImage3: file(
                name: { eq: "about-page-text-and-image-3" }
                extension: { eq: "jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE, layout: CONSTRAINED)
                }
            }
            textAndImage4: file(
                name: { eq: "about-page-text-and-image-4" }
                extension: { eq: "jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE, layout: CONSTRAINED)
                }
            }
            textAndImage7: file(
                name: { eq: "about-page-text-and-image-7" }
                extension: { eq: "jpg" }
            ) {
                childImageSharp {
                    gatsbyImageData(quality: 100, placeholder: NONE, layout: CONSTRAINED)
                }
            }
        }
    `);

    return (
        <Layout path={props.path}>
            <Seo title="About" />
            <MastheadLandingPage
                subheading="About us"
                heading="<div class='line'><span>We</span></div> <div class='line'><span>solve</span></div> <div class='line'><span class='neon-pink'>problems</span></div>"
                content="We’re a collaborative team of people all who share the same goal of getting things done and improving your business."
                imageWebp={mastheadImageWebp}
                imagePng={mastheadImagePng}
                backgroundColor="#ECECEC"
                textColor="#0E1828"
                gradient="linear-gradient(271.83deg, #874C98 12.09%, #8E4A97 14.03%, #A94192 23.73%, #BC3B8F 34.4%, #C7378D 44.1%, #CB368C 54.77%, #E32C59 75.14%, #E6273C 99.39%)"
            />
            <TextAndImage2
                subheading="Making digital work for you..."
                heading="Our purpose is simple – we’re here to help speed things up for your business. Our approach is equally straight forward: We get to know you, design a solution, and execute it for you."
                content="Our experience allows us to consistently deliver marketing and development that performs for our clients, who benefit from daily pro-active input to drive their businesses forward. <br/> <br/> It's our job to provide a wealth of strategic brainpower, a vast knowledge of technology, and an active imagination. "
                content2="Who we work with"
                clientLogos={data.clientLogos}
                imageMobile={data.textAndImage2}
                backgroundImage={data.textAndImage2}
                backgroundColor="#ECECEC"
                gradeint="linear-gradient(271.83deg,#874C98 12.09%,#8E4A97 14.03%,#A94192 23.73%,#BC3B8F 34.4%,#C7378D 44.1%,#CB368C 54.77%,#E32C59 75.14%,#E6273C 99.39%)"
            />
            <BigContent4
                column1Subtitle="01"
                column1Heading="We take care of everything"
                column1Content="We think of ourselves as an extension of your team. No task is too big or too small."
                column2Subtitle="02"
                column2Heading="Provide a fully managed service"
                column2Content="Our teams cover a wide range of specialities, including e-commerce, website management, branding, design, print, social and influencer marketing."
                column3Subtitle="03"
                column3Heading="Data Driven Activity"
                column3Content="We're here to help you measure and track your progress, always feeding campaign insights into strategy."
                column4Subtitle="04"
                column4Heading="We work alongside you"
                column4Content="We are a collaborative team, constantly adapting to the world around us and bound together by client satisfaction."
                column5Subtitle="05"
                column5Heading="Create tailored bespoke solutions"
                column5Content="As the client, you are the expert in your field. We will work together with you to create a plan that will incorporate our digital expertise to achieve your business goals."
                column6Subtitle="06"
                column6Heading="We solve your problems"
                column6Content="We’re here to help you achieve a change of speed or direction. We are specialists in overcoming the blockers to your business growth."
            />
            <Pattern1
                imageMobile={data.pattern1ImageMobile}
                imageDesktop={data.pattern1ImageDesktop}
            />
            <TextAndImage3
                heading="We get<br/> things done"
                content="It’s in the name. Digital Velocity is all about delivering highly effective campaigns at speed. We don’t disguise activity as achievement, we know that our service is only as good as the return that it generates, and will only ever ask you to judge us on our performance."
                image={data.textAndImage3}
                shapeColour="#E2246E"
            />
            <TextAndImage4
                heading="We're always moving forward"
                content="We are constantly adapting to the changes in the digital landscape, to ensure we're running the most efficient campaigns for our clients. We’re in it for the long haul, and it’s our job to inspire you at every opportunity, keeping you ahead of the curve."
                image={data.textAndImage4}
                backgroundColor="#0E1828"
                color="#fff"
                shapeColour="#5FDDEB"
            />
            <TextAndImage7
                heading="We’re a lovely bunch"
                content="Our people make this agency what it is today. We have a great team dynamic, working collaboratively together and having a laugh while we do. We believe in the power each member of the team, how our people can accelerate our business forward, and how we can facilitate their personal and professional growth."
                image={data.textAndImage7}
                shapeColour="#E2246E"
            />
            <PageNavigationBlock
                backgroundColour="grey"
                overlayTextColour="#5FDDEB"
                heading="Accelerate your business"
                subtitle="Next up - E-Commerce"
                content="Developed for retail, tailored for your business. No matter the size of your goals we can help your business take it’s next steps."
                image={pageNavigationBlockImage}
                linkText="Learn about our E-Commerce services"
                link="/ecommerce"
            />
        </Layout>
    );
};

export default AboutPage;
